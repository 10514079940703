import React from 'react'
import MainLayout from '@layouts/MainLayout'
import { ArrowForward } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { useTranslation, withTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import NextLink from '@imports/NextLink'

function Custom404() {
  const { t } = useTranslation(['meta'])
  return (
    <div>
      <Head>
        <title>{`${t('meta:404.title')} - Blue Assembly`}</title>
        <meta
          name="description"
          content={t('meta:home.description')}
        />
      </Head>
      <MainLayout>
        <Box sx={{ textAlign: 'center', py: 20 }}>
          <Typography variant="h4">404 - Page not found</Typography>
          <Button
            endIcon={<ArrowForward />}
            size="large"
            variant="contained"
            sx={{ mt: 5 }}
            aria-label="back to home"
          >
            <NextLink
              href="/"
              aria-label="back to home link"
            >
              Back to home
            </NextLink>
          </Button>
        </Box>
      </MainLayout>
    </div>
  )
}

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale)),
  },
})

export default withTranslation(['common'])(Custom404)
